<template>
  <v-container
    fluid
    fill-height
  >
    <v-menu
      open-on-hover
      bottom
      right
      offset-y
      transition="slide-y-transition"
      style="position: absolute; right: 0; top: 0; padding: 15px;"
    >
      <router-link
        v-ripple
        slot="activator"
        class="toolbar-items"
        to=""
      >
        <v-badge
          color="error"
          overlap
        >
          <span style="text-transform: uppercase; color: rgba(0,0,0,.87);">{{ selectedLocale.locale }}</span>
        </v-badge>
      </router-link>
      <v-card>
        <v-list dense>
          <v-list-tile
            v-for="language in languages"
            :key="language.locale"
            @click="changeLanguage(language)"
          >
            <v-list-tile-title
              v-text="$t(language.name)"
            />
          </v-list-tile>
        </v-list>
      </v-card>
    </v-menu>
    <v-layout
      align-center
      justify-center
    >
      <v-flex
        xs12
        sm8
        md4
        style="min-width: 500px"
      >
        <v-card class="elevation-12">
          <v-toolbar
            :color="'primary'"
            flat
          >
            <v-toolbar-title style="text-align: center;">
              <!-- <img
                v-if="forConasi"
                src="../../assets/conasi_logo.png"
                width="50%"> -->
              <img
                v-if="!forConasi"
                src="../../assets/e_voucher_logo_white.svg"
                width="50%">
              <img
                v-else
                src="../../assets/e_voucher_logo_white_conasi.png"
                width="50%">
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                :rules="[ ruleRequiredValue ]"
                v-model="username"
                :label="$t('login.username')"
                :name="$t('login.username')"
                prepend-icon="mdi-account"
                type="text"
                class="required"
                @keyup.enter="goToDashboard($event)"
              />
              <v-text-field
                id="password"
                :rules="[ ruleRequiredValue ]"
                v-model="password"
                :label="$t('login.password')"
                :name="$t('login.password')"
                prepend-icon="mdi-lock"
                type="password"
                class="required"
                @keyup.enter="goToDashboard($event)"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <span @click="onGoResetPassword"><a>{{ $t('login.forgotPassword') }}</a></span>
            <v-btn
              color="success"
              @click="goToDashboard($event)">{{ $t('login.login') }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card>
          <v-card-text>
            <v-layout wrap>
              <v-flex
                xs12
                sm12
                md12
                class="social-btn-class text-center"
              >
                <google-button-component
                  v-if="ENABLE_BINDING_GOOGLE"
                  :data-width="147"
                  style="margin: 0"
                  @onEmitData="googleBtnEmitData"
                />
              </v-flex>
              <v-flex
                xs12
                sm12
                md12
                class="social-btn-class"
              >
                <a
                  v-if="ENABLE_BINDING_FACEBOOK"
                  class="btn btn-block btn-social btn-facebook"
                  style="width: 215px; margin: auto"
                  @click="onClickLoginFacebook">
                  <span class="fa fa-facebook"/> {{ $t('common.loginWithFacebook') }}
                </a>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
        <div
          v-if="forConasi"
          class="text-center mt-2">
          <!-- <span>{{ $t('register.notHaveAccount') }} <a href="/register">{{ $t('register.createNewOne') }}</a></span> -->
          <span>
            <a
              href="https://conasi365.vn/pages/chinh-sach-bao-mat"
              target="_blank">{{ $t('common.privacyPolicy') }}</a> |
            <a
              href="https://conasi365.vn/pages/chinh-sach-giai-quyet-khieu-nai"
              target="_blank">{{ $t('common.termsCondition') }}</a></span>
        </div>
      </v-flex>
    </v-layout>
    <loading-bar :is-loading="isLoading"/>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar" />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { FOR_CONASI_TARGET } from 'utils/constants'
import ToastType from 'enum/toastType'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import stringUtils from 'utils/stringUtils'
import LoginFromType from 'enum/loginFromType'
import SocialNetworkType from 'enum/socialNetworkType'
import { initFbsdk } from '../../config/facebook_oAuth.js'
import StoreChannelType from 'enum/storeChannelType'
import GoogleButtonComponent from 'Components/GoogleButtonComponent'
export default {
  components: {
    GoogleButtonComponent
  },
  data: () => ({
    valid: true,
    username: '',
    password: '',
    isLoading: false,
    forConasi: FOR_CONASI_TARGET,
    ENABLE_BINDING_GOOGLE: process.env.VUE_APP_ENABLE_BINDING_GOOGLE.trim() === 'true',
    ENABLE_BINDING_FACEBOOK: process.env.VUE_APP_ENABLE_BINDING_FACEBOOK.trim() === 'true'
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'languages',
      'selectedLocale'
    ])
  },
  watch: {
  },
  mounted () {
    initFbsdk()
  },
  created () {
    initFbsdk()
  },
  methods: {
    /**
     * Go reset password
     */
    onGoResetPassword: function () {
      this.$router.push({
        path: 'forgot-password'
      })
    },
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    /**
     * Change language
     */
    changeLanguage (language) {
      this.$i18n.locale = language.locale
      this.$store.dispatch('changeLanguage', language)
    },
    validate () {
      if (this.$refs.form.validate()) {
        this.snackbar = true
        let filter = {
          username: this.username.toLowerCase().trim(),
          password: this.password,
          fromType: LoginFromType.ADMIN
        }
        this.isLoading = true
        this.LOGIN(filter).then(
          function (res) {
            this.isLoading = false
            let isLoginMobilePortal = sessionStorage.getItem('isLoginMobilePortal')
            if (isLoginMobilePortal) {
              this.$router.push({
                path: '/dashboard'
              })
            } else {
              this.$router.push({
                name: 'MobilePortalProductList',
                params: {
                  'storeChannelId': StoreChannelType.MOBILE_PORTAL
                }
              })
            }
          }.bind(this)
        ).catch(
          function (error) {
            this.isLoading = false
            if (error.message === stringUtils.NETWORK_ERROR) {
              this.ON_SHOW_TOAST({
                message: this.$t('common.networkError'),
                styleType: ToastType.ERROR
              })
            } else if (!functionUtils.isNull(error.response.data.message)) {
              if (error.response.status === 401) {
                this.ON_SHOW_TOAST({
                  message: this.$t('login.loginInvalid'),
                  styleType: ToastType.ERROR
                })
              } else {
                this.ON_SHOW_TOAST({
                  message: this.$t(error.response.data.message),
                  styleType: ToastType.ERROR
                })
              }
            } else {
              this.ON_SHOW_TOAST({
                message: this.$t('login.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }
          }.bind(this)
        )
      }
    },
    /**
     * Go dashboard
     */
    goToDashboard: function (event) {
      event.preventDefault()
      this.validate()
    },
    onClickLoginGoogle: function () {
      this.$gAuth
        .signIn()
        .then(GoogleUser => {
          // on success do something
          var profile = GoogleUser.getBasicProfile()
          if (Object.keys(profile).length > 0) {
            let id = profile.getId()
            this.checkGoogleLogin(id, SocialNetworkType.GOOGLE)
          }
        })
    },
    checkGoogleLogin: function (socialId, socialType) {
      let data = {
        socialId: socialId,
        socialType: socialType
      }
      this.isLoading = true
      this.LOGIN_WITH_SOCIAL_NETWORK(data).then(
        function () {
          this.isLoading = false
          this.$router.push({
            path: '/dashboard'
          })
        }.bind(this)
      ).catch(
        function (error) {
          this.isLoading = false
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              'message': this.$t(`${errorText}`),
              styletype: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              'message': this.$t('login.somethingWentWrong'),
              'styleType': ToastType.ERROR
            })
          }
        }.bind(this)
      )
    },
    onClickLoginFacebook: function () {
      window.FB.login(response => {
        if (response && response.authResponse) {
          window.FB.api(`/${response.authResponse.userID}`, userResponse => {
            if (userResponse) {
              this.checkGoogleLogin(userResponse.id, SocialNetworkType.FACEBOOK)
            }
          }, this.params)
        }
      }, this.params)
    },
    googleBtnEmitData: function (data) {
      if (data) {
        let id = data.id
        this.checkGoogleLogin(id, SocialNetworkType.GOOGLE)
      }
    },
    ...mapActions([
      'LOGIN',
      'ON_SHOW_TOAST',
      'LOGIN_WITH_SOCIAL_NETWORK'
    ])
  }
}
</script>
<style lang="scss" scoped>
.translate-toolbar .translate-toolbar__content{
  justify-content: flex-end !important;
  float: right;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
.v-toolbar .v-toolbar__content .v-toolbar__items .toolbar-items:hover {
  background: #e5e5e5;
}
.v-card__actions {
  display: flex;
  justify-content: space-between;
}
.social-btn-class {
  padding: 5px;
}
</style>
